//
// _table.scss
//

.table {
  thead {
    tr {
      th {
        font-weight: $font-weight-bold;
        border: none;
        &:first-child {
          border-top-left-radius: 10px;
        }
        &:last-child {
          border-top-right-radius: 10px;
        }
        height: 15px;
        padding: 0.25rem 0.75rem;
      }
    }
  }
  tbody {
    border-top: none !important;
  }

  .table-light {
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

body[data-layout-mode="dark"] {
  // table
  .table {
    border-color: $gray-dark-700;
    color: $monsta-yellow;

    th {
      background-color: $black;
      color: $gray-dark-700;
    }
  }

  .table-bordered {
    border-color: $gray-dark-300;

    th,
    td {
      border-color: $gray-dark-300;
    }
  }

  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: $gray-dark-300;
  }

  .table-striped > tbody > tr:nth-of-type(odd),
  .table-hover > tbody > tr:hover,
  .table .table-light {
    --bs-table-accent-bg: #363a38;
    color: $gray-dark-400;
  }

  // table
  .table-dark {
    background-color: $gray-dark-300;
    > :not(caption) > * > * {
      background-color: $gray-dark-300;
    }
  }
}

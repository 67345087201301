.monsta-primary-color {
  color: $primary;
  font-weight: $font-weight-bold;
}

.address-container {
  display: inline-flex;
  margin-left: 10px;
  @media screen and (max-width: 650px) {
    width: 100%;
    margin-left: 0px;
  }
}
.monsta-yellow-color {
  color: $monsta-yellow;
  font-weight: $font-weight-bold;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-control {
  @media screen and (max-width: 768px) {
    justify-content: space-between !important;
  }
}
.monsta-white-color {
  color: $white;
}

.monsta-card-highlight-text {
  font-size: 1.1rem;
}

.monsta-link {
  color: $monsta-yellow;
  text-decoration: underline !important;
  font-weight: bold;

  &:hover {
    color: $white;
  }
}

.monsta_transactions_detail {
  font-size: 1rem;
  border: 1px solid white;
  border-radius: 10px;
  padding: 30px 20px;
  margin-bottom: 30px;
  hr {
    opacity: 1;
  }
  .btn-more {
    color: #f6ed38;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  & > div {
    overflow: hidden;
    & > div:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  .value-row {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 18px;
      height: 18px;
    }
  }
}


.text-input-log{
  border-radius: 10px;
  background-color: #333;
  color: #FFF;
  font-size: .8rem;
  font-family:'Courier New', Courier, monospace;
  padding:20px;
  width: 98%;
  overflow-wrap: break-word;
  text-overflow: ellipsis;

}

.block-information{
  font-size: .8rem;
  overflow: hidden !important;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-card-light-alert {
  background-color: $primary;
  border-radius: 0.5rem;

  .card-title {
    color: $primary;
  }
}

.custom-card-light-primary-alert {
  background-color: $black;
  border: 1px solid $black;
  font-size: 1rem;
  border-radius: 0.5rem;

  .card-title {
    color: $primary;
  }
}

.custom-card-dark-primary-alert {
  background-color: $black;

  .card-title {
    color: $primary;
  }
}

.copy-address {
  margin-left: 0.5em;
  i.far {
    display: inline-block;
    padding: 0.5em 0.6em;
    font-size: 0.8rem;
    cursor: pointer;
  }
}

.flex-row {
  display: flex;
  align-items: center;
  gap: 5px;
  img {
    width: 18px;
    height: 18px;
  }
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}


.address-sub-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 575px) {
    display: block;
    .nav-tabs-custom.nav.nav-pills {
      justify-content: center;
    }
    h5 {
      margin-top: 20px;
      text-align: center;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
}

//
// _nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: $gray-700;
      font-weight: $font-weight-medium;
    }
  }
}

.nav-pills {
  > a {
    color: $gray-700;
    font-weight: $font-weight-medium;
  }
}

.nav-tabs-custom {
  .nav-item {
    position: relative;
    color: $dark;
    .nav-link {
      border: none;
      background-color: black;
      border-radius: 0;

      &.active {
        color: $primary;
        background-color: $monsta-yellow;
        &:after {
          transform: scale(1);
        }
      }
    }
  }

  &.card-header-tabs {
    border-bottom: none;

    .nav-link {
      padding: $card-cap-padding-y $nav-link-padding-x;
      font-weight: $font-weight-medium;
    }
  }
}

// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;

      .nav-icon {
        font-size: 24px;
      }
    }
  }
}

body[data-layout-mode="dark"] {
  // .nav-tabs
  .nav-link {
    color: $gray-dark-500;
  }

  .nav-tabs {
    border-color: $blue;
    font-size: 1rem;
    .nav-link {
      color: $gray-dark-600;
      &:focus,
      &:hover {
        border-color: $blue;
      }
      &.active {
        background-color: $blue;
        color: $white;

        border-color: $blue;
      }
    }
  }

  .nav-pills {
    .nav-link {
      color: $gray-dark-600;
      &.active {
        color: $black;
      }
    }
  }
}

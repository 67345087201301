// 
// _footer.scss
// 

.footer {
    bottom: 0;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    position: absolute;
    right: 0;
    color: $footer-color;
    left: 250px;
    height: $footer-height;
    background-color: $footer-bg;
    border-top: 1px solid $border-color;

    @media (max-width: 991.98px) {
        left: 0;
    }
}
  
// Enlarge menu
body[data-sidebar-size="sm"] {
    .footer {
        left: $sidebar-collapsed-width;
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }  
}

body[data-layout-mode="dark"] {
    .footer {
        background-color: $monsta-dark-green;
        color: $white;
        border-color: $blue;
    }
}
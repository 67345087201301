.tabs-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  .tab-item {
    padding: 5px 10px;
    color: white;
    cursor: pointer;
    &.active {
      border-radius: 5px;
      background-color: #11644f;
    }
  }
}

//
// _pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px !important;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0;
    text-align: center;
    line-height: 32px;
  }
}

// dark layout

body[data-layout-mode="dark"] {
  // pagination
  .page-link {
    background-color: transparent;
    border: none !important;
    box-shadow: none !important;
    color: $monsta-yellow;
    &:hover {
      color: $monsta-yellow;
    }
  }
  .page-item {
    &.disabled {
      .page-link {
        color: $gray-dark-400;
        background-color: transparent;
        border-color: transparent;
        opacity: 0.8;
      }
    }

    &.active {
      .page-link {
        color: $white;
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
}

.react-bs-table-sizePerPage-dropdown {
  #pageDropDown {
    background-color: black;
    height: 30px;
    display: flex;
    align-items: center;
  }
  .dropdown-menu {
    li a {
      color: white;
    }
  }
}

.pagination.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0;
}

.custom-pagination {
  display: flex;
  align-items: center;
  gap: 20px;
  & > span {
    cursor: pointer;
    white-space: nowrap;
    &:first-child,
    &:nth-child(2),
    &:nth-child(4),
    &:last-child {
      color: $monsta-yellow;
      &.disabled {
        color: $gray-dark-400;
      }
    }
    &:active {
      opacity: 0.5;
    }
  }
  @media screen and (max-width: 576px) {
    gap: 10px;
  }
}

.size-page-dropdown {
  .dropdown-toggle {
    height: 25px;
    display: flex;
    align-items: center;
    background: black;
  }
}
